<template>
    <main id="forgottenPsw">        
        <Header />
        <div class="hero-modal-page">
            <div class="container-xl  position-relative">
                    <!-- TITLE -->
                    <div class="row">                    
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div class="card bg-white form-inner-container  form-inner-container--narrow-content">
                                <div class="card-dialog">
                                    <div class="card-content text-center">    
                                        <ValidationObserver ref="observerFgPsw" v-slot="{ handleSubmit }" data-formname="forgottenPswForm">
                                            <form  @submit.prevent="handleSubmit(onSubmit)">            
                                                <img 
                                                    src="@/assets/imgs/forgot-pw.svg" 
                                                    class="img-fluid mb-4"                                                     
                                                >
                                                <h3 class="text-primary fw-700 fs-24 text-center w-100 mb-4">Elfelejtett jelszó</h3>
                                                <p class="text-center ps-4 pe-4 fs-16 forgott-pw-text text-secondary">
                                                    Ha elfelejtetted a jelszavad, add meg az <span style="whtie-space:nowrap">e-mail</span> címed, amivel regisztráltál és küldünk egy <span style="white-space:nowrap">e-mailt</span> az új jelszó generáláshoz.
                                                </p>
                                                <div class="text-left">
                                                    <CustomInput
                                                        v-model="email"
                                                        class="mt-5 mb-2 text-left"
                                                        :label="'E-mail cím'"
                                                        name="email"
                                                        rules="required|email" 
                                                        placeholder="Pl.: szaboeva@gmail.com"                                   
                                                    />
                                                </div>
                                                <div class="col-12 col-md-6 mx-auto" v-if="captcha_id">
                                                    <div v-if="captcha_img" class="mb-2 d-flex justify-content-start align-items-center">
                                                        <div  style="border:1px solid white;border-radius:5px" class="inline-flex bg-white">
                                                            <img v-if="captcha_img" :src="captcha_img" id="captchaImg" />
                                                        </div>
                                                        <div class="ms-2" style="width:20px; cursor:pointer" @click="setNewCaptcha()">
                                                            <img src="@/assets/imgs/ic-arrows-rotate-solid.svg" title="Újat kérek" class="img-fluid" />
                                                        </div>
                                                    </div>
                                                    <CustomInput
                                                        label="Captcha"
                                                        name="captcha"
                                                        v-model="captcha"
                                                        rules="required"
                                                    />
                                                    <div v-if="captcha_error">
                                                        <span class="input-error">Hibás captcha</span>
                                                    </div>
                                                </div>
                                                <p class="alert font-13" :class="`alert-${formSubmitStatus}`" v-if="sendMessage">{{sendMessage}}</p>
                                                <button type="submit" class="btn btn-secondary w-100">Küldés</button>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
            </div> 
        </div> 
        <Footer />
    </main>
</template>

<script>
import axios from 'axios'
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'

export default {
    components:{
        Header,
        Footer,
        CustomInput,
        ValidationObserver
    },
    data(){
        return{
            captcha_img: null,
            captcha_id: null,
            isConditionAccepted: false,
            captcha_error: false,
            captcha: null,
            email: "",
            sendMessage: null,  
            formSubmitStatus:null          
        }
    },
    watch: {
        email: function(){
            this.isConditionAccepted = true;
        },
        isConditionAccepted: function() {
                this.captcha_error = false;
                //get the capcha id
                axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                    this.captcha_id = response.data.id;
                
                    //get the captcha img
                    axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                        responseType: 'blob',
                    }).then(response => {                          
                        this.showCapchaImg(response.data)
                        this.captcha_error = false;

                    }).catch(function (err) {
                        //NOT SUCCESS msg send
                        console.log(err);      
                        this.captcha_error = true;
                        
                    });
                })
            }            
        
    },
    created(){
        if (document.querySelectorAll('meta[content="noindex, nofollow"]').length === 0) {
            var m = document.createElement('meta'); 
            m.name = 'robots'; 
            m.content = 'noindex, nofollow'; 
            document.head.appendChild(m);
        }
    },
    methods:{
        setNewCaptcha(){
            this.captcha_error = false;
            this.captcha = "";
            axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_new_id').then(response => {
                this.captcha_id = response.data.id;
            
                //get the captcha img
                axios.get(process.env.VUE_APP_BASE_URL + '/api/captcha/get_image?id='+this.captcha_id, {
                    responseType: 'blob',
                }).then(response => {                           
                    this.showCapchaImg(response.data) 
                    this.captcha_error = false;
                    
                }).catch(function (err) {
                    //NOT SUCCESS msg send
                    console.log(err);      
                    this.captcha_error = true;
                    
                });
            })
        },
        showCapchaImg(imgSource){                    
            const myImgUrl = URL.createObjectURL( imgSource );            
            this.captcha_img = myImgUrl     
        },
        onSubmit(){         
            this.sendMessage = null
            this.formSubmitResult= null
            
            this.post('forgottenpassword/sendmail',{email:this.email,captcha:this.captcha,captcha_id:this.captcha_id }).then(()=>{
                this.email = "";
                this.captcha_img = null;
                this.captcha_id = null;
                this.isConditionAccepted = false;
                this.captcha_error = false;
                this.captcha = null;
                this.formSubmitStatus = 'success'
                this.sendMessage = 'A jelszómódosításhoz szükséges adatokat elküldtük.'
                window.dataLayer.push({'event': 'form-submit', 'form-name': 'password-forgotten', 'form-submit':true, 'form-error': ''});
                this.$nextTick(() => this.$refs.observerFgPsw.reset());
            }).catch((err)=>{
                this.goToError(err)  
                
            })
        },
        goToError(err){            
            
          this.setErrorMsgs(err, 'observerFgPsw').then(()=>{       
            console.log('hiba')         
              let firstError = document.querySelector('#forgottenPsw .input-error')
              this.scrollToElement(firstError, 80)       
          }).catch(()=>{
              //the error not bound to a field        
              window.dataLayer.push({'event': 'form-submit', 'form-name': 'password-forgotten', 'form-submit':false, 'form-error': 'Error sending data'});    
            this.formSubmitStatus = 'danger'
            this.sendMessage= "Hiba az üzenetküldés során, kérjük, próbálja meg újra."
            this.setNewCaptcha();
          })                                   
        },
    }
}
</script>